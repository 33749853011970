<template>
  <my-window
    :width="770"
    :height="600"
    :left="left"
    :top="top"
    title="Listado"
    :closeAction="toggleShowTables"
  >
    <v-tabs
      v-model="tab"
      fixed-tabs
      background-color="rgb(36 46 61)"
      dark
    >
      <v-tab>
        Incidentes
      </v-tab>
      <v-tab>
        Camaras
      </v-tab>
      <v-tab>
        Unidades
      </v-tab>
      <v-tab>
        Botones de pánico
      </v-tab>
      <v-tab>
        Transporte Urbano
      </v-tab>
      <v-tab>
        Analiticos
      </v-tab>
    </v-tabs>
    <v-tabs-items v-model="tab" style="height: calc(100% - 48px); overflow: auto;">
      <v-tab-item :eager="true">
        <incidents-table :store="incidentsStore" :actions="incidentsActions"></incidents-table>
      </v-tab-item>
      <v-tab-item :eager="true">
        <cameras-table :store="camerasStore" :actions="camerasActions"></cameras-table>
      </v-tab-item>
      <v-tab-item :eager="true">
        <devices-table :store="devicesStore" :actions="devicesActions"></devices-table>
      </v-tab-item>
      <v-tab-item :eager="true">
        <sensors-table :store="sensorsStore" :actions="sensorsActions"></sensors-table>
      </v-tab-item>
      <v-tab-item :eager="true">
        <buses-table :store="busesStore" :actions="busesActions"></buses-table>
      </v-tab-item>
      <v-tab-item :eager="true">
        <lprs-table :store="lprsStore" :actions="lprsActions"></lprs-table>
      </v-tab-item>
    </v-tabs-items>
  </my-window>
</template>

<script>
import { mapActions, mapState } from 'vuex'

export default {
  components: {
    'my-window': () => import('@/components/Window.vue'),
    'cameras-table': () => import('@/components/map/tables/CamerasTable.vue'),
    'buses-table': () => import('@/components/map/tables/BusesTable.vue'),
    'lprs-table': () => import('@/components/map/tables/LprsTable.vue'),
    'devices-table': () => import('@/components/map/tables/DevicesTable.vue'),
    'sensors-table': () => import('@/components/map/tables/SensorsTable.vue'),
    'incidents-table': () => import('@/components/map/tables/IncidentsTable.vue')
  },
  props: {
    camerasStore: Object,
    busesStore: Object,
    lprsStore: Object,
    devicesStore: Object,
    sensorsStore: Object,
    incidentsStore: Object,
  },
  data() {
    return {
      tab: null,
      camerasActions: [{
        icon: '',
        action: (item) => {
          this.$parent.selectCamera(item, true)
        }
      }],
      busesActions: [{
        icon: '',
        action: (item) => {
          this.$parent.selectBus(item, true)
        }
      }],
      lprsActions: [{
        icon: '',
        action: (item) => {
          this.$parent.selectLpr(item, true)
        }
      }],
      devicesActions: [{
        icon: '',
        action: (item) => {
          this.$parent.selectDevice(item, true)
        }
      }],
      sensorsActions: [{
        icon: '',
        action: (item) => {
          this.$parent.selectSensor(item, true)
        }
      }],
      incidentsActions: [{
        icon: '',
        action: (item) => {
          this.$parent.selectIncident(item, true)
        }
      }]
    }
  },
  mounted() {
  },
  computed: {
    left() {
      return (window.innerWidth / 2) - (700 / 2)
    },
    top() {
      return (window.innerHeight / 2) - (640 / 2)
    }
  },
  methods: {
    ...mapActions('map', [
      'toggleShowTables'
    ])
  }
}
</script>

<style>

</style>
